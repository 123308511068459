import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token',
  })
};

const postDate = {
  jql: 'key="TICKET-831"',
  fields: ['comment', 'customfield_10070', 'issuetype', 'status', 'updated', 'description', 'created']
};


@Component({
  selector: 'app-jira-api',
  templateUrl: './jira-api.component.html',
  styleUrls: ['./jira-api.component.css']
})
export class JiraApiComponent implements OnInit {

  constructor(private http: HttpClient) { }

  private JiraUrl = 'https://nutselect.atlassian.net/rest/api/2/search';

  ngOnInit(): void {
    this.http.post(this.JiraUrl, httpOptions)
      .subscribe(response => {
        console.log(response);
      }, err => {
        console.log(err.message);
      }, () => {
        console.log('completed');
      });
  }

}
